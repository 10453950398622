import React, {useEffect, useRef, useState} from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import Reviews from './hooks/Reviews';
import { Link } from 'react-router-dom';
import Footer from './hooks/Footer';
import Progress from "./hooks/Progress";
import Socials from './hooks/Socials';

const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
    const elementRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            elementRef.current.classList.add('shake-5s');
          } else {
            elementRef.current.classList.remove('shake-5s');
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(elementRef.current);

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);



  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }
  
  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };
  
  const fabWhiteStyle = {
      color: 'common.white',
      bgcolor: 'primary',
      '&:hover': {
        color: '#29d090', // Change the color on hover
        bgcolor: '#007FFF',
      },
    };
    const [value, setValue] = React.useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const transitionDuration = 500;
  
    const fabs = [
      {
        color: 'primary',
        sx: fabStyle,
        icon: <StarIcon />, // Icon for Excellence
        label: 'Excellence',
      },
      {
        color: 'primary',
        sx: fabStyle,
        icon: <SecurityIcon />, // Icon for Integrity
        label: 'Integrity',
      },
      {
        color: 'primary',
        sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
        icon: <GroupIcon />, // Icon for Teamwork
        label: 'Teamwork',
      },
    ];

    const Accordion = ({ title, content }) => {
      const [isOpen, setIsOpen] = useState(false);
    
      return (
        <div className="border rounded shadow mb-4">
          <div
            className="flex justify-between items-center p-4 cursor-pointer bg-gray-200"
            onClick={() => setIsOpen(!isOpen)}
          >
            <h2 className="text-lg font-semibold">{title}</h2>
            <svg
              className={`w-6 h-6 transition-transform duration-300 transform text-[#1B9BF0] ${
                isOpen ? 'rotate-45' : ''
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
              />
            </svg>
          </div>
          {isOpen && (
            <div className="p-4 bg-white">
              <p>{content}</p> <br />
            </div>
          )}
        </div>
      );
    };
  return (
    <>
    <Progress/>
    <div className='box1 overflow-hidden'> 
            <section class="herox">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="logox.png" alt="" className="w-[10%]"/> */}
                        </div>
                </motion.div>
                 <div className='relative'>
                    <Navbar/>
                 </div>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Reliable Asset Recovery Partner!</p>
                                </motion.div>

                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Expert Team & Cutting-Edge<br/>Technology for Swift Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>
                                    Specializing in expert asset recovery services for both individuals and businesses, 
                                    we assure you of swift and seamless asset retrieval.
                                    </p>
                                </motion.div>

                                <div className='flex gap-2'>
                                    <div className='bg-[reen] h-fit flex gap-1 mr-0 mt-5'>
                                        <a href="/contact"
                                            
                                        >
                                            <button class="button-57" ><span class="text">Report Scam<i class="ri-arrow-right-line"></i></span><span></span></button>
                                        </a>
                                    </div>
                                    {/* <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                        <a href="https://revokedev.com/signup/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            <button class="button-58" ><span class="text">Sign-up Now<i class="ri-arrow-right-line"></i></span><span></span></button>
                                        </a>
                                    </div> */}
                                
                                </div>
                                <div className="w-full md:w-1/3">
         
          {/* <div className="flex space-x-4 pt-5">
          <a
            href="https://www.instagram.com/revokedev?igsh=MWc4M2U2OWl3d2JpZw=="
            className="text-white hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
            >
               <i className="ri-instagram-line text-[1.3rem]"></i>
          </a>


            <a href="mailto:revokedev@gmail.com" className="text-white hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="ri-mail-open-line text-[1.3rem]"></i>
            </a>

            <a href="https://x.com/revokedev?s=21" className="text-white hover:text-gray-500"
                target="_blank"
                rel="noopener noreferrer"
            >
            <i class="ri-twitter-x-line text-[1.2rem]"></i>
            </a>
            
            <a href="http://wa.me/+16173608778" className='hover:text-gray-500'
                target="_blank"
                rel="noopener noreferrer"
            >
              <i class="ri-whatsapp-line text-[1.3rem]"></i>
            </a>
            <a href="https://t.me/revoke_dev" className='hover:text-gray-500'
                target="_blank"
                rel="noopener noreferrer"
            >
             <i class="ri-telegram-line  text-[1.3rem]"></i>
            </a>
          </div> */}
        </div>
                         </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
<div>
  <Socials/>
</div>

    <div className='bg-[#000000] h-fit'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='flex justify-center items-center pt-10'>
                    <p className='font-bold text-white shake-5s'>Policy’s</p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Policy</p>
            </div>


            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="refund.png" alt="" className='w-[50px]'/>
                         </div>
                    </div>


                    <div>
                        <p className='font-bold pt-5 text-white'>Refund Policy</p>
                    </div>

                    <div className='py-3'>
                        <p className='text-white'>
                        With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                        </p>
                    </div>
                </motion.div>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="privacy.png" alt="" className='w-[50px]'/>
                         </div>
                    </div>


                    <div>
                        <p className='font-bold pt-5 text-white'>Privacy Policy</p>
                    </div>

                    <div className='py-3'>
                        <p className='text-white'>
                        Detailing our commitment to safeguarding clients' personal and financial information, our policy adheres to all pertinent data protection regulations. Rest assured, your sensitive data is secure with us.
                        </p>
                    </div>
                </motion.div>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="accuracy.png" alt="" className='w-[50px]'/>
                         </div>
                    </div>


                    <div>
                        <p className='font-bold pt-5 text-white'>Accuracy</p>
                    </div>

                    <div className='py-3'>
                        <p className='text-white'>
                        Discover the excellence of our versatile and dynamic team, proficient in various fields. Witness our seamless collaboration in achieving impeccable results, leaving no room for error.
                        </p>
                    </div>
                </motion.div>
            </div>
        </div>



        <section class="numbers mt-[5%]">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="recovery.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={37}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="customer-engagement.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11460}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="deal.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10930}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="encrypted.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={7940}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
            </section>


        <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <div className='flex justify-center items-center pt-10'>
                    <p className='font-bold text-white shake-5s'>Services</p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Services</p>
                </div>

            <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 xl:pb-5 pb-0 pt-[5%]'>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="crypto-wallet.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Crypto Currency Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, led by Money Revoke Experts and their dedicated team ofprofessionals.
                      </p>
                    </div>

                      <Tooltip title="Crypto Currency Recovery" arrow>
                          <a href="/crypto">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                </motion.div>
                <motion.div className='bg-[#16181c] h-full shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="love-and-romance.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Romance  and Pig Butchering scam Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment.
                      </p>
                    </div>

                      <Tooltip title="Romance Scam Recovery" arrow>
                          <a href="/romance">
                             <button className='Button__ pt-10'>Learn More</button>
                          </a>
                      </Tooltip>
                </motion.div>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="reset-password.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Forgotten Passwords Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      Lost your wallet or BIP38 password? RevokeDev's expert password recovery engineers specialize in swiftly recovering or decrypting cryptocurrency wallet passwords. With your approval, we initiate the recovery process based on your chosen service level. Our secure portal allows you to track the progress of your recovery and view a list of recoverable files. Trust RevokeDev for efficient and secure password recovery.
                      </p>
                    </div>

                      <Tooltip title="Forgotten Passwords Recovery" arrow>
                          <a href="/ForgottenPasswords">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                </motion.div>
               
                {/* <div className='bg-[#16181c] h-full shadow__box p-5'>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="unlocking.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Account Unlock & Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience of our solutions designed to overcome account lockouts effortlessly. At Revokedev, we specialize in swift account recovery, providing you with the means to regain control and peace of mind for your digital assets.
                      </p>
                    </div>

                      <Tooltip title="Account Unlock & Recovery" arrow>
                          <a href="/crypto">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                </div> */}
            </div>

            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10'>
            <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.5, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="pulling-up.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Rug Pull Scam Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      We offer a recovery process for rug pull victims by auditing smart contracts. Rug pulls involve deceptive crypto or NFT projects that attract investments and vanish, leaving assets worthless. We identify vulnerabilities, return value, and ensure secure withdrawals for affected investors. Our dedicated team at Revokedev is committed to restoring financial security for those impacted.
                      </p>
                    </div>

                      <Tooltip title="Rug Pull Scam Recovery" arrow>
                          <a href="/RugPull">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                </motion.div>
                <motion.div className='bg-[#16181c] h-fit shadow__box p-5'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <div className='bg--300 w-fit border__b'>
                        <div className='p-2'>
                            <img src="bitcoin-wallet.png" alt="" className='w-[54px]'/>
                         </div>
                    </div>

                    <div className='pt-5'>
                      <p className='font-bold text-white'>Wallet Drainer Recovery</p>
                    </div>

                    <div className='pt-3'>
                      <p className='text-white'>
                      Revokedev specializes in Wallet Drainer Recovery. If you've fallen victim to unauthorized transfers by clicking unsecured links, our dedicated team freezes the Attacker Contract Address (CA) and reverses assets back to victims, ensuring a swift and secure recovery process. Trust Revokedev to reclaim your funds and mitigate the impact of wallet drainer scams.
                      </p>
                    </div>

                      <Tooltip title="Wallet Drainer Recovery" arrow>
                          <a href="/WalletDrainer">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                </motion.div>
            </div>
        </div>


              <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/The_Top20Cybersecurity_Terms_You_Need_to_Know.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Excellence" {...a11yProps(0)} />
                                            <Tab label="Integrity" {...a11yProps(1)} />
                                            <Tab label="Teamwork" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Continuous Improvement</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                             Through continuous refinement, we work diligently and tirelessly to surpass your expectations. Our unwavering commitment ensures that we won't rest until we've achieved excellence and completed the job to your utmost satisfaction.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Reliable Integrity</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Unwavering Ethical Commitment: We uphold the highest standards of trustworthiness and ethical conduct. Our reliable integrity is the cornerstone of our service, ensuring your complete confidence in our work. We are dedicated to maintaining your trust, always, because your peace of mind matters.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Resilient Teamwork</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Collaborative Determination: Our resilient teamwork thrives on collaboration and determination. We unite as a dedicated team, facing challenges head-on and delivering results together. With unwavering determination, we work seamlessly to achieve your goals, every step of the way.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> <i class="ri-double-quotes-l text-[#007FFF]"></i> Testimonies <i class="ri-double-quotes-r text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Reviews </p>
                </div>
                  <Reviews/>
            </div>


          <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'><i class="ri-question-mark text-[#007FFF]"></i> F.A.Q <i class="ri-question-mark text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s text-center'>Frequently Ask Questions</p>
                </div>

            <div>

            </div>
          </div>


          <div className='mx-auto overflow-hidden md:max-w-7xl'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Ploicy"
                                    content="Revokedev is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
            </div>


            <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>



              <Footer/>

    </div>    
    </>
  )
}

export default Home