import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import WalletDrainerReview from "./hooks/WalletDrainerReview";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const WalletDrainer = () => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
      
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };
      
      const fabWhiteStyle = {
          color: 'common.white',
          bgcolor: 'primary',
          '&:hover': {
            color: '#29d090', // Change the color on hover
            bgcolor: '#007FFF',
          },
        };
      
     
        const [value, setValue] = React.useState(0);
        const isMobile = useMediaQuery('(max-width: 600px)');
      
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
      
        const transitionDuration = 500;
      
        const fabs = [
          {
            color: 'primary',
            sx: fabStyle,
            icon: <StarIcon />, // Icon for Excellence
            label: 'Excellence',
          },
          {
            color: 'primary',
            sx: fabStyle,
            icon: <SecurityIcon />, // Icon for Integrity
            label: 'Integrity',
          },
          {
            color: 'primary',
            sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
            icon: <GroupIcon />, // Icon for Teamwork
            label: 'Teamwork',
          },
        ];
  return (
   <>
   <Progress/>
   <div>
   <section class="Drainer overflow-hidden">
            <div className='relative'>
                <Navbar/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                        Wallet Drainer Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
   </div>
   
   <div className='bg-black h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl pt-10'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 shadow__box px-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[1%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-white'>
                                Revokedev stands as a steadfast ally for clients who have fallen victim to the nefarious impact of rug pulls, a deceitful maneuver prevalent in the cryptocurrency and NFT landscape. These scams inflict not only financial losses but also severe blows to investor confidence, leaving individuals grappling with the aftermath of shattered trust and devalued assets.
                                Our tailored recovery process is designed to address the multifaceted challenges posed by rug pulls. We initiate a thorough examination through smart contract audits, delving deep into the intricate details of these deceptive projects. This meticulous scrutiny allows us to unveil the cunning tactics employed by unscrupulous developers, identifying vulnerabilities and weak points in the smart contracts.
                                </p>
                            </div> <br />

                            <div>
                                <p className='text-white'>
                                Beyond the recovery of lost assets, our mission extends to empowering clients on their journey toward financial reconstruction. We understand that the impact of such scams transcends monetary losses, often leaving individuals in a state of financial distress and emotional turmoil. Revokedev endeavors not only to reclaim financial resources but also to provide support, guidance, and a pathway towards rebuilding a robust financial future.
                                </p>
                            </div>
                        </div>
                        <div className='bg-reen-600 h-fit'>
                            <div className='flex justify-center items-center xl:pt-5 pt-0'>
                                <img src="https://miro.medium.com/v2/resize:fit:1400/1*FN7IgBUNmS4fCOpA0WK_RA.jpeg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>
                   
                </div>
            </div>



            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'>Services <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Key Services </p>
                </div>



                <div className="mx-auto overflow-hidden  md:max-w-7xl text-white">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="phishing.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help from <br/>Phishing Attacks</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="anti-virus.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3"> Recovery from <br/>Malware and Keyloggers</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="disadvantage.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help from<br/>Fake Wallet Apps</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="sim-card.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help from<br/>SIM Swapping</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="impersonal.png" alt="" className="w-[55px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help from<br/> Impersonation Scams</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="tokens.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help from Fake  <br/> ICOs & Token Sales</p>
                        </div>
                    </motion.div>
                    {/* <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="social-engineering.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Social Engineering <br/> Recovery</p>
                        </div>
                    </motion.div> */}
                    
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shieldo.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div> 

            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://augustschell.com/my_content/uploads/2018/01/cybersecurity-team.png" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Recovery" {...a11yProps(0)} />
                                            <Tab label="Approach" {...a11yProps(1)} />
                                            <Tab label="Support" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Expertise in Diverse Scam Recovery</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        RevokeDev excels in a comprehensive range of cryptocurrency scam recoveries, including Wallet Drainer scenarios. Our experienced team understands the nuances of various attack vectors, providing specialized expertise to swiftly and effectively recover funds. Choose RevokeDev for a proven track record in navigating the complexities of wallet drainer scams.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Advanced Security Measures</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        We employ cutting-edge security protocols to not only recover funds but also to enhance the overall security of your digital assets. Our proactive approach includes freezing attacker contract addresses and implementing robust security measures to prevent future unauthorized transactions. RevokeDev ensures that your cryptocurrency is safeguarded against potential threats after recovery.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Client-Centric Approach and Support</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        At RevokeDev, we prioritize the well-being of our clients. Our client-centric approach involves transparent communication, personalized support, and a commitment to guiding individuals affected by wallet drainer scams. We don't just recover funds; we provide ongoing support to empower clients on their journey towards financial recovery and security.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

        <div className='mx-auto overflow-hidden max-w-7xl'>
                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> <i class="ri-double-quotes-l text-[#007FFF]"></i> Testimonies <i class="ri-double-quotes-r text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Reviews </p>
                </div>
            <WalletDrainerReview/>
        </div>


        <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>



              <Footer/>

   </div>
   </>
  )
}

export default WalletDrainer;