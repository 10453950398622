import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy-FmjLFzkNKB5DDfYxJVS83Bwdkmuv_baAQ1C1lzwD80g7VZBMfK9-XBC1lhLbl4bno8&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Remy Desmond</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
                <p className='text-[#e6e4e4] text-center pt-[4%] pb-5 pr-1 pl-1'>
                RevokeDev proved to be a beacon of hope when I fell victim to a wallet drainer scam. Having lost $50,000, I was devastated. However, their expert team not only showcased unparalleled expertise in recovering funds but also did so with remarkable speed. Every penny lost was successfully retrieved. The transparency and guidance throughout the recovery process were reassuring. I can confidently say that RevokeDev is the go-to solution for anyone facing the aftermath of a wallet drainer. Highly recommended!
                </p>
            </div>
          <div className='flex justify-center text-center'>
           <div className='pt-4'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-4'>Philadelphia, USA  🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos2.spareroom.co.uk/images/flatshare/listings/large/42/43/42432094.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Asher Jaxson</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[4%] pb-5 pr-1 pl-1'>
          My experience with RevokeDev was nothing short of extraordinary. After being targeted by a wallet drainer scam and losing $14,000, I was desperate for a solution. RevokeDev not only recovered the entire amount efficiently but also demonstrated exceptional support throughout the recovery journey. Their team's expertise and commitment to client satisfaction set them apart. For anyone grappling with the aftermath of a wallet drainer, RevokeDev is the trustworthy ally you need. Grateful for their outstanding services!
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[4%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[4%]'>Mississauga, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://qph.cf2.quoracdn.net/main-qimg-c193ab4c4573c1e3da43fd8d6e8f0cde-lq' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Lexi Barron</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[2%] pb-5 pr-1 pl-1'>
          RevokeDev deserves all the praise for their outstanding service. Falling prey to a wallet drainer scam left me disheartened, having lost $66,000. The recovery process was seamless, thanks to RevokeDev's advanced security measures and expert approach. Not only did they successfully retrieve my funds, but they also implemented safeguards to prevent future incidents. The ongoing support and commitment to client security make RevokeDev a reliable choice. I wholeheartedly recommend their services to anyone seeking recovery from wallet drainer scams.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>florida, USA  🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.blackandbeauties.com/maj/upload/compte/photos/photo_18fbb67bdca7f00aff4ade33a98ce503.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Valerie .B</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[4%] pb-5 pr-1 pl-1'>
          Navigating the aftermath of a wallet drainer scam was emotionally draining, but RevokeDev's professionalism and expertise provided a glimmer of hope. Having lost €43,000, I was skeptical about recovery. However, RevokeDev not only successfully recovered $9,500 but also provided unwavering support throughout. Their commitment to client satisfaction, coupled with their transparent approach, makes them stand out. I'm immensely thankful for RevokeDev's exceptional services and recommend them to anyone seeking recovery from such scams.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[4%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[4%]'>Corsica, France 🇫🇷  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.dailymail.co.uk/i/pix/2016/06/24/00/359A946300000578-3656779-image-m-52_1466723885613.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Charlotte Harrison</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
          RevokeDev is undoubtedly the best in the business. When a wallet drainer took £35,000 from me, I thought my funds were lost forever. However, RevokeDev's comprehensive recovery process not only retrieved the entire amount but also ensured ongoing support for the security of my assets. Their commitment to client satisfaction, coupled with their transparent communication, instills confidence. If you find yourself a victim of a wallet drainer, RevokeDev is the trustworthy ally you need. Highly recommended!
          </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>Scotland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
