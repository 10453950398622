import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://static-cdn.jtvnw.net/jtv_user_pictures/7be68a11-9eb3-4d35-9a36-ef75870956e7-profile_image-300x300.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Christopher Smith</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            In the aftermath of a rug pull, I felt helpless until I found RevokeDev. Their swift recovery process and transparent communication brought not only financial relief but also peace of mind. The team's dedication and expertise in auditing smart contracts reassured me throughout the recovery journey. I'm immensely grateful for their professionalism and support during a challenging time
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-4'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-4'>California, USA  🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNjO1FLSX9h1JNlJU9UwTo8X0j9NRSvz_d9O6B5REju3BwjvCkdmWQP9abpBgL6MVtnCs&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Michael Brown</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            RevokeDev was my lifeline after falling prey to a rug pull scam. Their meticulous smart contract audits and unwavering commitment not only recovered my lost assets but also guided me towards a financial rebirth. The personalized support and expert advice offered by RevokeDev were instrumental in helping me navigate the complexities of blockchain scams. Thank you, RevokeDev, for not just reclaiming my assets but for being a trustworthy partner on my journey to financial recovery.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>San Francisco, USA  🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/EjRnWbHX0AIb-HO.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Stephan Rodriguez</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
          RevokeDev restored more than just my lost assets; they restored my trust. Their thorough smart contract audits and personalized support are unmatched in the field. RevokeDev's commitment to transparency and communication made the recovery process seamless. Highly recommend for anyone facing rug pull challenges – they are more than just a recovery service; they are a trusted ally.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[13%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[13%]'>Madrid, Spain 🇪🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos2.spareroom.co.uk/images/flatshare/listings/large/49/47/49477486.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Madison Williams</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            RevokeDev went beyond recovery; they offered guidance and understanding during a difficult time. Their team's expertise in smart contract audits ensured a successful recovery, but it was their empathetic approach that truly stood out. RevokeDev not only reclaimed my assets but also provided valuable insights to prevent future scams. I couldn't be more thankful for their comprehensive support and dedication.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[15%]'>Oregon, USA 🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/cb281daa21bc036868a52fe76634fa82.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Olivia Campbell</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
          Falling victim to a rug pull was devastating. RevokeDev became my beacon of resilience. Their successful recovery efforts and constant support not only reclaimed my assets but also empowered me for the future. The team's commitment to client education on the intricacies of blockchain security instilled confidence. Truly grateful for RevokeDev's unwavering support and professionalism throughout the recovery process.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>Scotland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
